import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
   return (
      <div className='privacy-policy'>
         <div className='privacy-policy__container'>
            <h2 className='privacy-policy__title'>Политика в отношении обработки персональных данных</h2>
            <p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Закона Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных предпринимаемые компанией Linkside (далее – Оператор).</p>
            <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных в том числе защиты прав на неприкосновенность частной жизни личную и семейную тайну.</p>
            <p>Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации которую Оператор может получить о посетителях веб-сайта <a href="https://linkside.kz">https://linkside.kz</a>.</p>

            <h3 className='privacy-policy__subtitle'>Основные понятия используемые в Политике</h3>
            <ul>
               <li>Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</li>
               <li>Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев если обработка необходима для уточнения персональных данных).</li>
               <li>Веб-сайт — совокупность графических и информационных материалов а также программ для ЭВМ и баз данных обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://linkside.kz">https://linkside.kz</a>.</li>
               <li>Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</li>
               <li>Обезличивание персональных данных — действия в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</li>
               <li>Обработка персональных данных — любое действие (операция) или совокупность действий (операций) совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными включая сбор запись систематизацию накопление хранение уточнение (обновление изменение) извлечение использование передачу (распространение предоставление доступ) обезличивание блокирование удаление уничтожение персональных данных.</li>
               <li>Оператор — государственный орган муниципальный орган юридическое или физическое лицо самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных а также определяющие цели обработки персональных данных состав персональных данных подлежащих обработке действия (операции) совершаемые с персональными данными.</li>
               <li>Персональные данные — любая информация относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://linkside.kz">https://linkside.kz</a>.</li>
               <li>Персональные данные разрешенные субъектом персональных данных для распространения — персональные данные доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных разрешенных субъектом персональных данных для распространения в порядке предусмотренном Законом Республики Казахстан «О персональных данных и их защите».</li>
               <li>Пользователь — любой посетитель веб-сайта <a href="https://linkside.kz">https://linkside.kz</a>.</li>
               <li>Предоставление персональных данных — действия направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</li>
               <li>Распространение персональных данных — любые действия направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц в том числе обнародование персональных данных в средствах массовой информации размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</li>
               <li>Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства иностранному физическому или иностранному юридическому лицу.</li>
               <li>Уничтожение персональных данных — любые действия в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.</li>
            </ul>
            <h3 className='privacy-policy__subtitle'>Основные права и обязанности Оператора</h3>
            <p>Оператор имеет право:</p>
            <ul>
               <li>получать от субъекта персональных данных достоверные информацию и/или документы содержащие персональные данные;</li>
               <li>в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта если это необходимо для выполнения обязательств по договору или иным законным основаниям.</li>
            </ul>
            <p>Оператор обязан:</p>
            <ul>
               <li>предоставлять субъекту персональных данных по его просьбе информацию касающуюся обработки его персональных данных;</li>
               <li>принимать правовые организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним уничтожения изменения блокирования копирования предоставления распространения персональных данных а также от иных неправомерных действий в отношении персональных данных;</li>
            </ul>
            <h3 className='privacy-policy__subtitle'>Основные права и обязанности субъектов персональных данных</h3>
            <p>Субъекты персональных данных имеют право:</p>
            <ul>
               <li>получать информацию касающуюся обработки его персональных данных;</li>
               <li>требовать от оператора уточнения его персональных данных их блокирования или уничтожения в случае если персональные данные являются неполными устаревшими неточными незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
               <li>на отзыв согласия на обработку персональных данных;</li>
               <li>обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;</li>
            </ul>
            <h3 className='privacy-policy__subtitle'>Принципы обработки персональных данных</h3>
            <ul>
               <li>Обработка персональных данных осуществляется на законной и справедливой основе.</li>
               <li>Обработка персональных данных ограничивается достижением конкретных заранее определенных и законных целей.</li>
               <li>Не допускается обработка персональных данных несовместимая с целями сбора персональных данных.</li>
               <li>Оператор принимает необходимые меры по удалению или уточнению неполных или неточных данных.</li>
            </ul>
            <h3 className='privacy-policy__subtitle'>Условия обработки персональных данных</h3>
            <ul>
               <li>Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.</li>
               <li>Обработка персональных данных необходима для исполнения договора стороной которого является субъект персональных данных а также для заключения договора по инициативе субъекта персональных данных.</li>
               <li>Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц при условии что при этом не нарушаются права и свободы субъекта персональных данных.</li>
            </ul>
            <h3 className='privacy-policy__subtitle'>Порядок сбора хранения передачи и других видов обработки персональных данных</h3>
            <p>Безопасность персональных данных которые обрабатываются Оператором обеспечивается путем реализации правовых организационных и технических мер необходимых для выполнения в полном объеме требований законодательства Республики Казахстан в области защиты персональных данных.</p>
            <p>Персональные данные Пользователя никогда ни при каких условиях не будут переданы третьим лицам за исключением случаев связанных с исполнением действующего законодательства или если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.</p>
            <h3 className='privacy-policy__subtitle'>Трансграничная передача персональных данных</h3>
            <p>Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных.</p>
            <h3 className='privacy-policy__subtitle'>Конфиденциальность</h3>
            <p>Оператор и иные лица получившие доступ к персональным данным обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных если иное не предусмотрено законодательством Республики Казахстан.</p>
            <h3 className='privacy-policy__subtitle'>Заключительные положения</h3>
            <p>Пользователь может получить любые разъяснения по интересующим вопросам касающимся обработки его персональных данных обратившись к Оператору с помощью электронной почты Linksidekz@mail.ru.</p>
            <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
            <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://linkside.kz/privacy">https://linkside.kz/privacy</a>.</p>
         </div>
      </div>
   );
};

export default PrivacyPolicy;
