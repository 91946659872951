import image7 from './assets/image 7.jpg';
import image8 from './assets/image 8.jpg';
import image9 from './assets/image 9.jpg';
import image10 from './assets/image 10.jpg';
import image11 from './assets/image 11.jpg';
import image12 from './assets/image 12.jpg';
import image13 from './assets/image 13.jpg';
import image14 from './assets/image 14.jpg';
import image15 from './assets/image 15.jpg';
import image16 from './assets/image 16.jpg';
import image17 from './assets/image 17.jpg';
import image18 from './assets/image 18.jpg';

const servicesData = [
   {
      title: 'Электрика',
      description: [
         'Проектирование электрических сетей',
         'Монтаж и установка электрической проводки',
         'Установка розеток, выключателей и светильников',
         'Установка систем защиты (УЗО, автоматические выключатели)',
         'Обслуживание и ремонт электрических систем'
      ],
      image: image7
   },
   {
      title: 'Отопление',
      description: [
         'Проектирование системы отопления',
         'Монтаж радиаторов и конвекторов',
         'Установка насосов и трубопроводов для отопления',
         'Обслуживание и ремонт системы отопления'
      ],
      image: image8
   },
   {
      title: 'Теплые полы и стены',
      description: [
         'Проектирование систем теплых полов и стен',
         'Укладка теплых полов и стен',
         'Обслуживание и ремонт систем теплых полов и стен'
      ],
      image: image9
   },
   {
      title: 'Водоснабжение',
      description: [
         'Проектирование систем водоснабжения',
         'Монтаж трубопроводов для холодной и горячей воды',
         'Установка сантехнического оборудования (раковины, унитазы, душевые кабины)',
         'Обслуживание систем водоснабжения'
      ],
      image: image10
   },
   {
      title: 'Канализация',
      description: [
         'Проектирование систем канализации',
         'Монтаж трубопроводов для канализации',
         'Установка и обслуживание систем канализации'
      ],
      image: image11
   },
   {
      title: 'Система вентиляции',
      description: [
         'Проектирование систем вентиляции',
         'Монтаж вентиляционных систем (приточно-вытяжная вентиляция)',
         'Обслуживание и ремонт вентиляционных систем'
      ],
      image: image12
   },
   {
      title: 'Кондиционирование',
      description: [
         'Проектирование систем кондиционирования',
         'Монтаж кондиционеров',
         'Обслуживание и ремонт кондиционеров'
      ],
      image: image13
   },
   {
      title: 'Пожарная и охранная сигнализация',
      description: [
         'Проектирование систем пожарной сигнализации',
         'Монтаж датчиков дыма и тепла',
         'Обслуживание и ремонт систем пожарной сигнализации'
      ],
      image: image14
   },
   {
      title: 'Умный дом',
      description: [
         'Проектирование системы умного дома',
         'Монтаж устройств для управления освещением, отоплением, безопасностью',
         'Настройка и обслуживание системы умного дома'
      ],
      image: image15
   },
   {
      title: 'Энергоэффективные решения',
      description: [
         'Проектирование энергоэффективных систем',
         'Монтаж солнечных панелей',
         'Обслуживание и ремонт энергоэффективных систем'
      ],
      image: image16
   },
   {
      title: 'Системы контроля доступа',
      description: [
         'Проектирование систем контроля доступа',
         'Монтаж систем видеонаблюдения',
         'Обслуживание и ремонт систем контроля доступа'
      ],
      image: image17
   },
   {
      title: 'Радиаторы и конвекторы',
      description: [
         'Проектирование систем с радиаторами и конвекторами',
         'Монтаж радиаторов и конвекторов',
         'Обслуживание и ремонт радиаторов и конвекторов'
      ],
      image: image18
   }
];

export default servicesData;
